<template>
  <div>
    <ul class="list-unstyled">
      <li :class="$style.item" v-for="item in list" :key="item.id">
        <a @click="toTarget(item.id)" :class="$style.itemLink">
          <div :class="$style.itemPic" v-if="item.icon">
            <i :class="item.icon"></i>
          </div>
          <a-avatar
          v-if="item.img"
            :size="64"
            :src="item.img"
            icon="user"
            class="mr-3"
            />
          <div class="mr-2">
            <div>{{ item.title }}</div>
            <div class="text-muted" v-html="item.description"></div>
            <div class="text-muted" v-if="item.date">{{ String(moment(item.date).calendar(null, {
                  sameDay: '[I dag]',
                  nextDay: '[I morgen]',
                  nextWeek: 'dddd',
                  lastDay: '[I går]',
                  lastWeek: 'dddd',
                  sameElse: 'DD/MM/YYYY'
              })) }}
            </div>
          </div>
          <div :class="$style.itemAction">
            <a-button type="primary" @click="lauchAction(item.id)" v-if="action">
              <i :class="actionIcon" ></i>
            </a-button>
            <span v-else/>
          </div>
        </a>
      </li>
      <!-- <li :class="$style.item">
         <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fe fe-mail"/>
          </div>
          <div class="mr-2">
            <div>Message Removed</div>
            <div class="text-muted">2 hours ago</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fe fe-grid"/>
          </div>
          <div class="mr-2">
            <div>Parcel Received</div>
            <div class="text-muted">6 hours ago</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fe fe-database"/>
          </div>
          <div class="mr-2">
            <div>Parcel Recived</div>
            <div class="text-muted">15 hours ago</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fe fe-flag"/>
          </div>
          <div class="mr-2">
            <div>User Activated</div>
            <div class="text-muted">2 days ago</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
          </div>
        </a>
      </li> -->
    </ul>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'KitList5',
  props: {
    list: {
      type: Array,
    },
    target: {
      type: String,
    },
    action: {
      type: Boolean,
      default: false,
    },
    actionIcon: {
      type: String,
      default: 'fa fa-check',
    },
  },
  methods: {
    moment,
    toTarget(id) {
      if (this.target) {
        const destination = `/${this.target}/${id}`
        console.log(destination)
        this.$router.push({ path: destination })
      }
    },
    lauchAction(id) {
      this.$emit('launchAction', id)
    },
  },
}
</script>
<style lang="scss" module>
  @import './style.module.scss';
</style>
